import React, { useCallback, useEffect, useState, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import t1 from '../../../images/screens/passenger/1s.png';
import t2 from '../../../images/screens/passenger/2s.png';
import t3 from '../../../images/screens/passenger/3s.png';
import t5 from '../../../images/screens/passenger/5s.png';
import t9 from '../../../images/screens/passenger/9s.png';
import t10 from '../../../images/screens/passenger/10s.png';

import n1 from '../../../images/screens/passenger/en1/en1_c2.png';
import n2 from '../../../images/screens/passenger/en2/en2.png';
import n3 from '../../../images/screens/passenger/en3/en3.png';
import n5 from '../../../images/screens/passenger/en5/en5.png';
import n9 from '../../../images/screens/passenger/en9/en9.png';
import n10 from '../../../images/screens/passenger/en10/en10.png';

import w1 from '../../../images/screens/passenger/Final/webp/1s.webp';
import w2 from '../../../images/screens/passenger/Final/webp/2s.webp';
import w3 from '../../../images/screens/passenger/Final/webp/3s.webp';
import w4 from '../../../images/screens/passenger/Final/webp/4s.webp';
import w5 from '../../../images/screens/passenger/Final/webp/5s.webp';
import w9 from '../../../images/screens/passenger/Final/webp/9s.webp';
import w10 from '../../../images/screens/passenger/Final/webp/10s.webp';
import ResponsiveImage from '../../../utils/ResponsiveImage/ResponsiveImage';
import SkeletonLoader from '../../../utils/SkeletonLoader/SkeletonLoader';

import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import DefaultLanguageInterface from '../../../interfaces';

const LazyHomeCarousel: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            align: 'start',
            slidesToScroll: 1,
            containScroll: false,
            dragFree: false,
            loop: true
        },
        [
            Autoplay({
                delay: 3500,
                stopOnInteraction: false,
                playOnInit: true
            })
        ]
    );
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [visibleSlides, setVisibleSlides] = useState(4);
    const [isTextAnimating, setIsTextAnimating] = useState(false);

    const screens = [
        {
            images: {
                mobile: { webp: w1, fallback: w1 },
                tablet: { webp: w1, fallback: w1 },
                desktop: { webp: w1, fallback: w1 }
            },
            alt: languageData.home.passenger_screen_1
        },
        {
            images: {
                mobile: { webp: w3, fallback: w3 },
                tablet: { webp: w3, fallback: w3 },
                desktop: { webp: w3, fallback: w3 }
            },
            alt: languageData.home.passenger_screen_2
        },
        {
            images: {
                mobile: { webp: w4, fallback: w4 },
                tablet: { webp: w4, fallback: w4 },
                desktop: { webp: w4, fallback: w4 }
            },
            alt: languageData.home.passenger_screen_3
        },

        {
            images: {
                mobile: { webp: w5, fallback: w5 },
                tablet: { webp: w5, fallback: w5 },
                desktop: { webp: w5, fallback: w5 }
            },
            alt: languageData.home.passenger_screen_4
        },

        {
            images: {
                mobile: { webp: w9, fallback: w9 },
                tablet: { webp: w9, fallback: w9 },
                desktop: { webp: w9, fallback: w9 }
            },
            alt: languageData.home.passenger_screen_5
        },
        {
            images: {
                mobile: { webp: w2, fallback: w2 },
                tablet: { webp: w2, fallback: w2 },
                desktop: { webp: w2, fallback: w2 }
            },
            alt: languageData.home.passenger_screen_6
        },


        {
            images: {
                mobile: { webp: w10, fallback: w10 },
                tablet: { webp: w10, fallback: w10 },
                desktop: { webp: w10, fallback: w10 }
            },
            alt: languageData.home.passenger_screen_7
        },
    ];

    const totalDots = screens.length;

    const onSelect = useCallback((api: any) => {
        setSelectedIndex(api.selectedScrollSnap());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        const onSelect = () => {
            setIsTextAnimating(true);
            setTimeout(() => {
                setSelectedIndex(emblaApi.selectedScrollSnap());
                setIsTextAnimating(false);
            }, 300); // Match this with animation duration
        };

        emblaApi.on('select', onSelect);

        const handleResize = () => {
            if (window.innerWidth < 640) {
                setVisibleSlides(1);
            } else if (window.innerWidth < 768) {
                setVisibleSlides(2);
            } else if (window.innerWidth < 1024) {
                setVisibleSlides(3);
            } else {
                setVisibleSlides(4);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            emblaApi.off('select', onSelect);
            window.removeEventListener('resize', handleResize);
        };
    }, [emblaApi]);
    const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

    return (
        <div className="w-full max-w-[1000px] m-auto">
            <div className="relative h-8 mb-8 sm:mb-12">
                <div className="absolute w-full">
                    <p
                        className={`
                            w-full text-neutral-700 text-sm sm:text-base sm:text-center max-w-3xl mx-auto
                            transition-all duration-300 ease-in-out
                            ${isTextAnimating ? 'opacity-0 ' : 'opacity-100 scale-100'}
                        `}
                    >
                        {screens[selectedIndex].alt}
                    </p>
                </div>
            </div>

            <div className="overflow-hidden" ref={emblaRef}>
                <div className="flex pb-8">
                    {screens.map((screen, index) => (
                        <div key={index} className={`flex-[0_0_100%] sm:flex-[0_0_50%] md:flex-[0_0_33.333%] lg:flex-[0_0_25%] min-w-0 select-none`}>
                            <div className="relative h-[420px] py-[15px] w-full flex items-center justify-center">
                                <div className='absolute h-full w-full flex items-center justify-center z-0'>
                                    <div className='shadow-[5px_5px_20px_rgba(0,0,0,0.6)] rounded-3xl'>
                                        <div className='bg-white h-[380px] rounded-3xl w-[180px] z-0'>
                                        </div>
                                    </div>
                                </div>
                                <ResponsiveImage
                                    images={screen.images}
                                    alt={screen.alt}
                                    className="z-10 object-contain h-full h-[390px] sm:h-[390px]"
                                    innerClassName='object-contain h-full h-[390px] sm:h-[390px]'
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex justify-center">
                {Array.from({ length: screens.length }).map((_, index) => (
                    <button
                        key={index}
                        className={`w-2 h-2 rounded-full mx-1 ${index === selectedIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
                        onClick={() => scrollTo(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default LazyHomeCarousel;